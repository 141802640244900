import { defineStore } from "pinia";
import { type IUpdateProjectGroupDto, type IUserForms } from "~/types";

export interface IProject {
  id?: number;
  title: string;
}
export interface IProjectGroup {
  projectgroupid: number;
  projectid: number;
  groupid: number;

  ischecked: boolean;

  projectname: string;
  groupname: string;
}

export interface IUserProjects {
  projectId: number;
  projectTitle: string;
  isChecked: boolean;
}

export const useProjectsStore = defineStore("projects", () => {
  const { $toast } = useNuxtApp();
  const { data: sessionData } = useAuth();
  const loggedIn = computed(() =>
    useAuth().data.value?.accessToken ? true : false
  );

  const loading = ref<boolean>(false);
  const projects = ref<IProject[]>([]);

  const currentProject = ref<IProject>(); // global
  const userProjects = ref<IProject[]>([]); // для показа в AppHeader.Autocomplete
  const userFormsByProject = ref<IUserForms[]>();
  const crutchProjectId = ref<number>(-1); // для переноса формы в другой проект
  // const projectsCount = ref(0);

  const findAll = async () => {
    loading.value = true;
    const { data, error } = await useCustomFetch<IProject[]>("/projects", {});

    if (error.value) {
      if (process.client) $toast.error(error.value?.message);
      console.error(
        `[${side()}][stores.db.projects.findAll] error: `,
        error.value?.message
      );
    } else {
      projects.value = data.value ? data.value : [];
    }
    loading.value = false;
  };

  const create = async (value: IProject) => {
    const { error } = await useCustomFetch("/projects", {
      method: "POST",
      body: value,
    });
    if (error.value) {
      $toast.error(error.value.message);
    } else {
      await findAll();
    }
  };

  const update = async (value: IProject) => {
    const { error } = await useCustomFetch(`/projects/${value.id}`, {
      method: "PATCH",
      body: value,
    });
    if (error.value) {
      // toast.error(error.value.message);
    } else {
      await findAll();
    }
  };
  const remove = async (value: IProject) => {
    const { error } = await useCustomFetch(`/projects/${value.id}`, {
      method: "DELETE",
    });
    if (error.value) {
      $toast.error(error.value.message);
    } else {
      await findAll();
    }
  };

  const updateProjectGroup = async (value: IUpdateProjectGroupDto) => {
    // console.log(`******** updateProjectGroup`, JSON.stringify(value));

    const { data, error } = await useCustomFetch("/projects/groups", {
      method: "POST",
      body: value,
    });
    if (error.value) {
      $toast.error(error.value.message);
    } else {
      await findAll();
    }
  };

  // загрузка проектов юзера (есть к ним доступ)
  const fetchUserProjects = async () => {
    if (!loggedIn) {
      ce("stores.db.projects.fetchUserProjects", `not loggedIn`);
      return;
    }
    if (!sessionData.value?.id) {
      ce("stores.db.projects.fetchUserProjects", `not id in auth session`);
      return;
    }

    const { data, error } = await useCustomFetch<IUserProjects[]>(
      `projects/users/${sessionData.value?.id}`
    );
    if (error.value) {
      console.log(`[AppHeader] error: `, error.value);
      ce(
        "stores.db.projects.fetchUserProjects",
        `error: ${JSON.stringify(error.value)}`
      );
    } else {
      if (data.value) {
        // cl("stores.db.projects.fetchUserProjects", data.value.length);
        userProjects.value = data.value
          .filter((item) => item.isChecked)
          .map((item) => {
            return { id: item.projectId, title: item.projectTitle };
          });
      }
      // projectsCount.value = projects.value.length;

      if (!currentProject.value) {
        currentProject.value = userProjects.value[0];
      }
    }
  };

  const fetchUserFormsByProject = async () => {
    // if (!currentProject.value) return [];
    // if (process.server) return;
    if (!sessionData.value?.id) {
      cl(
        "stores.db.projects.fetchUserFormsByProject",
        `not id in auth session`
      );
      return [];
    }
    if (!currentProject.value) {
      return [];
    }
    if (!currentProject.value.id) {
      return [];
    }
    const userId = sessionData.value?.id ? sessionData.value?.id : -1;

    const { data, error } = await useCustomFetch<IUserForms[]>(
      `users/forms/projects/${currentProject.value?.id}/users/${userId}`
    );
    if (error.value) {
      console.log(
        `[stores.db.projects.fetchUserFormsByProject] error: `,
        error.value
      );
    } else {
      // cl(
      //   "Dispatch.loadForms",
      //   `forms.length: ${JSON.stringify(data.value?.length)}`
      // );
      userFormsByProject.value = data.value as IUserForms[];
      // cl(
      //   `stores.db.projects.fetchUserFormsByProject`,
      //   `size: ${data.value?.length}`
      // );
      // cl(
      //   `[stores.db.projects.fetchUserFormsByProject] `,
      //   `forms: ${JSON.stringify(userFormsByProject.value)}`
      // );
    }
  };

  return {
    loading,
    projects,
    create,
    update,
    remove,
    findAll,
    crutchProjectId,

    updateProjectGroup,

    fetchUserProjects,
    currentProject,
    userProjects,

    fetchUserFormsByProject,
    userFormsByProject,


  };
});
